import schengenCountries from '../constants/schengen';

function diffDays(date1, date2) {
  let oneDay = 24*60*60*1000; // One full day in ms

  return Math.round(Math.abs((date1.getTime() - date2.getTime())/(oneDay)));
}

function updateItinerary(itinerary) {
  let totalEuCycle = 180;
  let daysAllowedInEu = 90;

  let padDays = new Array(daysAllowedInEu).fill(0); // Pad with days allowed in EU
  let days = [].concat(padDays);

  for (var i = 0; i < itinerary.length; i++) {
    let curStop = itinerary[i];

    if (schengenCountries.indexOf(curStop.country) !== -1) {
      curStop.inEu = true;
    } else {
      curStop.inEu = false;
    }

    if (curStop.entry && curStop.exit) {
      let duration = this.diffDays(new Date(curStop.entry), new Date(curStop.exit));

      let newDays;
      if (curStop.inEu) {
        newDays = new Array(duration).fill(1);
      } else {
        newDays = new Array(duration).fill(0);
      }
      days = days.concat(newDays);

      let last180Days = days.slice(-1 * totalEuCycle);
      let daysInEu = last180Days.reduce((a, b) => a + b, 0);

      let totDaysLeftInEu = (90 - daysInEu) % 180;

      curStop.total = days.length - padDays.length;
      curStop.duration = duration;
      curStop.daysInEu = (totDaysLeftInEu < daysAllowedInEu) ? totDaysLeftInEu : daysAllowedInEu;
    }
  }

  return itinerary;
}

export default {
  diffDays,
  updateItinerary
};