import React, { Component } from 'react';
import Modal from 'react-modal';
import schengenCountries from './constants/schengen';

if (process.env.NODE_ENV !== 'test') { Modal.setAppElement('#root'); }

const customStyles = {
  content : {
    width: '80%',
    marginLeft: '10%',
    height: '80vh',
    marginTop: '10vh',
    top: 'inherit',
    bottom: 'inherit',
    left: 'inherit',
    right: 'inherit',
  }
};

class AboutModal extends Component {

  render() {
    return (
      <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.props.closeModal}
          contentLabel="About EuCalc.com"
          style={customStyles}
        >
          <div>
            <h2 className="w3-center w3-container w3-text-light-green">About EuCalc.com - Multi-Stop Schengen Calculator</h2>
            <h3 className="w3-container w3-light-green w3-text-white">What</h3>
            <p>
              This calculator helps people that have a 90-day Visa (aka US residents, Canadians, etc) check whether their travel itinerary falls within the Schengen regulations. <a href="https://ec.europa.eu/home-affairs/what-we-do/policies/borders-and-visas/schengen_en" target="_blank" rel="noopener noreferrer">Read more about Schengen here</a>. 
            </p>

            <h3 className="w3-container w3-light-green w3-text-white">Why</h3>
            <p>
              There is no shortage of Schengen calculators out there. However, I couldn't find a single one that fit my needs entirely. Thus, this was born. 
            </p>

            <h3 className="w3-container w3-light-green w3-text-white">Which Countries Are in the Schengen Region?</h3>
            <ul>
              {schengenCountries.map((country) => {
                let countryText = country.replace(/\b\w/g, l => l.toUpperCase());
                return (<li key={country}>{countryText}</li>);
              })}
            </ul>
            <p className="w3-container w3-pale-red w3-leftbar w3-border-red">
              Notice: Please use caution when planning your trip with this calculator. We are not held liable for any miscalculation or planning in advance. 
            </p>
          </div>
        </Modal>
    );
  }
}

export default AboutModal;
