import React, { Component } from 'react';

import countries from './constants/countries';
import schengenCountries from './constants/schengen';

class CountryDropDown extends Component {
  constructor(props) {
    super(props);

    this.selectedCountry = this.selectedCountry.bind(this);
  }

  selectedCountry(country) {
    this.props.onChange(country);
  }

  render() {
    return (
      <select onChange={this.selectedCountry} value={this.props.selected}>
        {countries.map((country) => {
          let lcCountry = country.toLowerCase();
          let inEu = schengenCountries.indexOf(lcCountry) !== -1;
          let optionText = country + ((inEu) ? ' (In EU)' : '');
          return (<option value={lcCountry} key={lcCountry}>{optionText}</option>);
        })}
      </select>
    );
  }
}

export default CountryDropDown;
