import React, { Component } from 'react';
import CountryDropDown from './CountryDropDown';

import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

class Stop extends Component {
  constructor(props) {
    super(props);

    this.handleEntryChange = this.handleEntryChange.bind(this);
    this.handleExitChange = this.handleExitChange.bind(this);
    this.handleSelectedCountry = this.handleSelectedCountry.bind(this);
  }

  handleEntryChange(entryDate) {
    this.props.updateDetail(this.props.index, 'entry', entryDate);
  }

  handleExitChange(exitDate) {
    this.props.updateDetail(this.props.index, 'exit', exitDate);
  }

  handleSelectedCountry(e) {
    this.props.updateDetail(this.props.index, 'country', e.target.selectedOptions[0].value);
  }

  render() {
    let selectedEnterDate = (this.props.info.entry) ? new Date(this.props.info.entry) : null;
    let selectedExitDate = (this.props.info.exit) ? new Date(this.props.info.exit) : selectedEnterDate;
    let negDaysLeftInEu = this.props.info.daysInEu < 0;

    return (
      <tr>
        <td data-label="Entry">
          {<DatePicker
            className="entryDatePicker"
            ref="entryDatePicker"
            dateFormat={"dd/MM/yyyy"}
            selected={selectedEnterDate}
            onChange={this.handleEntryChange}
          />}
        </td>
        <td data-label="Exit">
          {<DatePicker
            className="exitDatePicker"
            ref="exitDatePicker"
            dateFormat={"dd/MM/yyyy"}
            minDate={selectedEnterDate}
            selected={selectedExitDate}
            onChange={this.handleExitChange}
          />}
        </td>
        <td data-label="Country">{<CountryDropDown onChange={this.handleSelectedCountry} selected={this.props.info.country || undefined} />}</td>
        <td data-label="Duration" className={(this.props.info.inEu ? "w3-text-light-green" : "")}>{this.props.info.duration}</td>
        <td data-label="Total Duration">{this.props.info.total}</td>
        <td data-label="Days Left In Eu" className={negDaysLeftInEu ? "w3-red" : ''}>{this.props.info.daysInEu}</td>
      </tr>
    );
  }
}

export default Stop;
