import React, { Component } from 'react';
import './App.css';
import Itinerary from './Itinerary';
import AboutModal from './AboutModal';

import euBackground from './img/europe-map.jpg';

const dateUtils = require('./utils/date_utils').default;

class App extends Component {
  constructor(props) {
    super(props);

    let itinerary = this.getItinerary();

    this.state = {
      itinerary,
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setStopDetail = this.setStopDetail.bind(this);
    this.addStop = this.addStop.bind(this);
    this.resetItinerary = this.resetItinerary.bind(this);
    this.getStatus = this.getStatus.bind(this);
  }

  componentDidMount() {
    let updated = dateUtils.updateItinerary(this.state.itinerary);
    this.setItinerary(updated);
  }

  getItinerary() {
    let itinerary = JSON.parse(window.localStorage.getItem('itinerary'));

    // Return with locally saved itinerary, or default to one stop starting today
    if (itinerary) {
      return itinerary;
    } else {
      let today = new Date();
      let tmrw = new Date();
      tmrw.setDate(today.getDate() + 1);

      let startItinerary = [{ entry: today, exit: tmrw, country: 'austria'}];
      return startItinerary;
    }
  }

  setItinerary(itinerary) {
    this.setState({
      itinerary
    });

    localStorage.setItem('itinerary', JSON.stringify(itinerary));
  }

  setStopDetail(index, key, value) {
    let newItinerary = this.state.itinerary.slice();
    newItinerary[index][key] = value;

    let updated = dateUtils.updateItinerary(newItinerary);
    this.setItinerary(updated);
  }

  addStop() {
    let emptyStop = { entry: null, exit: null, country: null };

    let itineraryLength = this.state.itinerary.length;
    if (itineraryLength > 0 && this.state.itinerary[itineraryLength - 1].exit) {
      emptyStop.entry = this.state.itinerary[itineraryLength - 1].exit;
      emptyStop.exit = this.state.itinerary[itineraryLength - 1].exit;
    }

    this.setState(previousState => ({
      itinerary: [...previousState.itinerary, emptyStop]
    }));
  }

  resetItinerary() {
    let confirm = window.confirm("Are you sure you want to reset your trip? You won't be able to recover it. ");

    if (confirm) {
      this.setItinerary([]);
    }
  }

  getStatus() {
    let retVal = {
      inTheClear: true,
    };

    let issueCountries = [];

    for (var i = 0; i < this.state.itinerary.length; i++) {
      let curStop = this.state.itinerary[i];
      if (curStop.daysInEu < 0) {
        retVal.inTheClear = false;
        issueCountries.push(curStop.country);
      }
    }

    if (retVal.inTheClear) {
      retVal.text = `All is well, your trip is cleared! `;
    } else {
      let countriesText = "";
      let countriesLen = issueCountries.length;
      for (var j = 0; j < countriesLen; j++) {
        if (j !== 0 && j === issueCountries.length - 1) {
          countriesText += ", and "; // Oxford comma
        } else if (j !== 0) {
          countriesText += ", ";
        }

        countriesText += issueCountries[j].replace(/\b\w/g, l => l.toUpperCase());
      }

      retVal.text = `Whoops! You might need to take another look at some of the dates you have planned for ${countriesText}.`;
    }

    return retVal;
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    let status = this.getStatus();
    let statusPanelClass = status.inTheClear ? "w3-light-green" : "w3-red";

    return (
      <div className="App">
        <div id="body-wrap">
          <header className="w3-container w3-light-green" style={{backgroundImage: `url(${euBackground})`, backgroundSize: 'cover', backgroundPositionY: '60%'}}>
            <h1 className="w3-text-white">Multi-Stop Schengen Calculator</h1>
          </header>
          <div className="w3-container w3-margin-bottom">
            <h2 className="w3-text-light-green">Calculate How Many Days You Can be in the EU Schengen Region as a Visitor</h2>
            <div className="w3-row w3-center">
              <div className="w3-col m1">&nbsp;</div>
              <div className="w3-col s12 m10 l10">
                <Itinerary itinerary={this.state.itinerary} setStopDetail={this.setStopDetail} />
                {this.state.itinerary.length > 0 &&
                  <p className={`trip-status w3-panel ${statusPanelClass} w3-card-4 w3-padding w3-round w3-text-white`}>{status.text}</p>
                }
              </div>
            </div>
            <div className="w3-row s12 m4 l4 w3-center w3-margin-top w3-bar">
              <button id="add-stop" onClick={this.addStop} className="w3-button w3-light-green w3-round w3-margin-right w3-text-white">Add Stop</button>
              {this.state.itinerary.length > 0 &&
                <button id="reset" onClick={this.resetItinerary} className="w3-button w3-red w3-round">Reset Itinerary</button>
              }
            </div>
          </div>
        </div>
        <footer className="w3-container w3-center">
          <p className="w3-text-light-green">
            Made by <a href="http://tomer.shvueli.com" target="_blank" rel="noopener noreferrer">Tomer</a> from all around the <a href="http://wherethehellaretomerandmichelle.com" target="_blank" rel="noopener noreferrer"><span role="img" aria-label="World">🌍</span></a> | <span className="about-link" onClick={this.openModal}>About</span>
          </p>
        </footer>
        <AboutModal modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} />
      </div>
    );
  }
}

export default App;
