import React, { Component } from 'react';
import './Itinerary.css';
import Stop from './Stop';

class Itinerary extends Component {

  render() {
    return (
      this.props.itinerary && this.props.itinerary.length ?
      <table className="w3-table w3-bordered">
        <thead>
          <tr>
            <th>Entry Date</th>
            <th>Exit Date</th>
            <th>Country</th>
            <th># of Days</th>
            <th>Total Day Tally</th>
            <th># of Days Left in EU</th>
          </tr>
        </thead>
        <tbody>
          {this.props.itinerary.map((stop, idx) => {
            let props = {
              info: stop,
              index: idx,
              updateDetail: this.props.setStopDetail
            };

            return (<Stop key={idx} {...props} />);
          })}
        </tbody>
      </table>
      :
      <p>Add stops.</p>
    );
  }
}

export default Itinerary;
