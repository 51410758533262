const schengenCountries = [
  "austria",
  "belgium",
  "czech Republic",
  "denmark",
  "estonia",
  "finland",
  "france",
  "germany",
  "greece",
  "hungary",
  "iceland",
  "italy",
  "latvia",
  "lithuania",
  "luxembourg",
  "malta",
  "netherlands",
  "norway",
  "poland",
  "portugal",
  "slovakia",
  "slovenia",
  "spain",
  "sweden",
  "switzerland"
];

module.exports = schengenCountries;